<template>
  <div>
    <vs-card v-can="permissions.retry">
      <vs-row
        vs-justify="center"
        vs-type="flex"
      >
        <h4 class="text-primary">
          حدد عدة رسائل لإعادة إرسالها
        </h4>
      </vs-row>
      <vs-row
        vs-justify="center"
        vs-type="flex"
      >
        <div class="demo-alignment">
          <vs-button
            @click="confirmResendMultipleRecords()"
            color="primary"
            type="filled"
          >
            إعادة إرسال
          </vs-button>
        </div>
      </vs-row>
    </vs-card>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitDeleteMultipleRecords="deleteFailedJobs($event, null, true)"
    />

    <vs-popup
      title="تفاصيل الرسالة"
      :active.sync="popupActive"
      :button-close-hidden="true"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 px-4">
        <div>
          <label>المعرّف :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="message.id"
          />
        </div>

        <div>
          <label>نوع الرسالة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="message.type"
          />
        </div>

        <div>
          <label>رقم المستقبل :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="message.receiver"
          />
        </div>

        <div
          class="col-span-3"
        >
          <label>نص الرسالة :</label>
          <vs-textarea
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="message.text"
          />
        </div>

        <div class="col-span-3">
          <label>سبب الفشل :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="message.failed_reason"
          />
        </div>

        <div class="col-span-3">
          <label>تاريخ الرسالة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="message.date"
          />
        </div>
      </div>


      <div class="flex justify-center items-center mt-8 px-4">
        <vs-button
          @click="popupActive=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
  import AgTable from "@/app/shared/shared-components/ag-grid/AgTable";
  import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
  import toasted from "@/app/shared/utilities/toasted";
  import AgButton from "../shared-components/ag-grid/AgButton";
  import AgTableBtnCell from "../shared-components/ag-grid/AgTableBtnCell";

  const failedJobsRepository = SharedRepositoryFactory.get('failedJobsRepository');

  export default {
    name: "FailedJobsMain",
    components: {
      AgTable
    },
    data() {
      return {
        gridOptions: null,
        gridApi: null,
        rowData: [],
        generalTypes: [],
        popupActive: false,
        message: {
          id: null,
          type: '',
          receiver: '',
          text:'',
          date: '',
          failed_reason: '',
        },
        permissions: {
          retry: 'retry_failed_messages'
        }
      };
    },
    methods: {
      createColDefs() {
        let self = this;
        return [
          {
            headerName: 'المعرف',
            field: 'id',
            filter: 'agTextColumnFilter',
            suppressSizeToFit: true,
            headerCheckboxSelection: true,
            checkboxSelection: true,
          },
          {
            headerName: 'نوع الرسالة',
            field: 'type',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'المستقبل',
            field: 'receiver',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'نص الرسالة',
            field: 'text',
            filter: 'agTextColumnFilter',
            width: 500,
          },
          {
            headerName: 'سبب الفشل',
            field: 'failed_reason',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'التاريخ',
            field: 'date',
            filter: 'agTextColumnFilter',
          },
          {
            headerName: 'إعادة الإرسال',
            cellRendererFramework: AgButton,
            field: 'id',
            filter: false,
            cellRendererParams: {
              size: 'small',
              color: 'primary',
              name: 'إعادة الإرسال',
              permission: 'retry_failed_messages',
              click(id) {
                self.confirmResendMultipleRecords(id);
              }
            }
          },
          {
            headerName: "الإجراءات",
            field: "id",
            cellRendererFramework: AgTableBtnCell,
            cellRendererParams: {
              viewRecordDetails: function (record) {
                self.viewRecordDetails(record);
              },
              deleteRecord: function (id, rowIndex) {
                self.deleteFailedJobs(id, rowIndex);
              },
              actions: ["view", "delete"],
            },
          },
        ];
      },
      viewRecordDetails(record) {
        this.popupActive = true;
        this.message = record;
      },
      confirmResendMultipleRecords(){
        if (!this.gridOptions.api.getSelectedRows().length)
          return;
        let recordsIds = this.gridOptions.api.getSelectedRows().map(element => element.id);
        let title = ' هل تريد إعادة إرسال ' + recordsIds.length + '  من الرسائل ؟ ';
        this.$vs.dialog({
          type: 'confirm',
          color: 'primary',
          title: title,
          text: 'في حال الموافقة سيتم إعادة إرسال الرسائل المحددة',
          acceptText: 'موافق',
          accept: this.retrySending,
          parameters: recordsIds
        });
      },
      async retrySending(ids) {
        let isMultiple = this.gridOptions.api.getSelectedRows().length > 1;
        let res = await failedJobsRepository.retrySending(ids,isMultiple);
        if(res){
          toasted.success('تمت إعادة الإرسال');
          this.rowData= [];
          this.fetchAllFailedJobs();
        }
        else toasted.failed('حدث خطأ ما');
      },
      async fetchAllFailedJobs() {
        this.fetchMessages(await failedJobsRepository.fetchAllFailedJobs());
      },
      fetchMessages(data) {
        if(data.length !== 0){
        data.forEach(item =>{
          let messageType = Object.keys(item.data)[0];
          this.rowData.push({
            'id' : item.id,
            'type': messageType,
            'receiver': item.data[messageType].receiver,
            'text': item.data[messageType].message,
            'date':  new Date(item.timestamp).toLocaleDateString("en-US"),
            'failed_reason': item.failedReason ,
          });
      });
        }
        else this.rowData= [];
        },
      async deleteFailedJobs(ids, index, isMultiple = false) {
        if (isMultiple) ids = ids[0].map(Number);
        let response = await failedJobsRepository.deleteFailedJobs(ids,isMultiple);
          if (response) {
            if (isMultiple) {
              this.rowData= [];
              this.fetchAllFailedJobs();
            }
            else {
              this.rowData.splice(index, 1);
              this.rowData = [...this.rowData];
            }
          }
      },
    },
    created() {
      this.gridOptions = {};
      this.gridOptions.columnDefs = this.createColDefs();
      this.fetchAllFailedJobs();
    },
    mounted() {
      this.gridApi = this.gridOptions.api;
    }
  };
</script>

<style>
  .vs-textarea {
    height: 200px;
  }
</style>
